<template>
  <div class="content_container list_view seller_view">
    <div class="inner">
      <div class="top_filter_wrap">
            <div class="filter_inner">
                <div class="btn_filter" @click="showFilter = true">
                    <p>검색필터</p>
                    <div class="icon_wrap">
                        <img src="@/assets/images/common/icon_filter_b.svg" />
                    </div>
                </div>
                <div class="search_wrapper">
                    <input
                    type="text"
                    placeholder="검색하려는 판매자의 닉네임 혹은 해시태그를 입력하신 후 Enter를 눌러주세요"
                    @input="searchInput = $event.target.value"
                    :value="searchInput"
                    @keyup.enter="search"
                    />
                    <button type="button" class="btn_search" @click="search"></button>
                </div>
                <div class="fill_select">
                    <select v-model="sortBy">
                        <option :value="{ by: 'enrollment_date', option: 'down' }">
                            최신 순
                        </option>
                        <option :value="{ by: 'enrollment_date', option: 'up' }">
                            오래된 순
                        </option>
                    </select>
                </div>
            </div>
      </div>

      <!--상세검색필터 시작-->
      <div
        class="screen_filter"
        v-if="showFilter"
        @click="showFilter = false"
      ></div>
      <div class="filter_detail_wrap">
        <button
          type="button"
          class="btn_close"
          v-if="showFilter"
          @click="showFilter = false"
        >
          <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
        </button>
        <div class="filter_detail" v-show="showFilter">
          <table class="fill filter_list">
            <tr>
              <th>
                <p>카테고리</p>
              </th>
              <td>
                <ul class="submenu fill_check">
                  <li
                    v-for="(item, i) in this.basicData.category.filter(
                      (el) => el.level == 2
                    )"
                    :key="i"
                  >
                    <label>
                      <input
                        type="checkbox"
                        class="seller_category"
                        :value="item.category_pk"
                        @click="submenuCheck($event, 'category', item.content)"
                      />
                      {{ item.content }}
                    </label>
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <th>
                <p>활동지역</p>
              </th>
              <td class="area_table">
                <ul class="submenu fill_check">
                  <li class="category">
                    <label> 국내 </label>
                  </li>
                  <div class="area">
                    <li
                      v-for="(item, i) in this.basicData.area.filter(
                        (el) => el.division == '국내'
                      )"
                      :key="i"
                    >
                      <label>
                        <input
                          type="checkbox"
                          class="seller_area"
                          :value="item.area_pk"
                          @click="submenuCheck($event, 'area', item.content)"
                        />
                        {{ item.content }}
                      </label>
                    </li>
                  </div>
                </ul>
                <ul class="area">
                  <li class="category li_border">
                    <label> 전 세계 </label>
                  </li>
                  <div class="area">
                    <li
                      v-for="(item, i) in this.basicData.area.filter(
                        (el) => el.division == '전세계'
                      )"
                      :key="i"
                    >
                      <label>
                        <input
                          type="checkbox"
                          class="seller_area"
                          :value="item.area_pk"
                          @click="submenuCheck($event, 'area', item.content)"
                        />
                        {{ item.content }}
                      </label>
                    </li>
                  </div>
                </ul>
              </td>
            </tr>

            <tr>
              <th>
                <p>판매채널</p>
              </th>
              <td>
                <ul class="submenu fill_check">
                  <li v-for="(item, i) in this.basicData.channel" :key="i">
                    <label>
                      <input
                        type="checkbox"
                        class="seller_channel"
                        :value="item.channel_pk"
                        @click="submenuCheck($event, 'channel', item.content)"
                      />
                      {{ item.content }}
                    </label>
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <th>
                <p>판매경력</p>
              </th>
              <td>
                <ul class="submenu fill_check">
                  <li v-for="(item, i) in this.basicData.career" :key="i">
                    <label>
                      <input
                        type="checkbox"
                        class="seller_career"
                        :value="item.career_pk"
                        @click="
                          submenuCheck($event, 'career', item.sale_user_content)
                        "
                      />
                      {{ item.sale_user_content }}
                    </label>
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <th>
                <p>준비예산</p>
              </th>
              <td>
                <ul class="submenu fill_check">
                  <li v-for="(item, i) in this.basicData.budget" :key="i">
                    <label>
                      <input
                        type="checkbox"
                        class="seller_budget"
                        :value="item.budget_pk"
                        @click="submenuCheck($event, 'budget', item.content)"
                      />
                      {{ item.content }}
                    </label>
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <th>
                <p>사업자유형</p>
              </th>
              <td>
                <ul class="submenu fill_check">
                  <li
                    v-for="(item, i) in this.basicData.businessman_division"
                    :key="i"
                  >
                    <label>
                      <input
                        type="checkbox"
                        class="seller_businessman_division"
                        :value="item.businessman_division_pk"
                        @click="
                          submenuCheck(
                            $event,
                            'businessman_division',
                            item.content
                          )
                        "
                      />
                      {{ item.content }}
                    </label>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
          <div class="btn_wrapper">
            <button type="button" class="common_button" @click="applyCheck">
              적용
            </button>
            <button type="button" class="sub_button" @click="resetCheck">
              리셋
            </button>
          </div>
        </div>
      </div>
      <!--상세검색필터 끝-->

      <!--상품 시작-->
      <div class="div_wrapper">
        <div class="right_con">
          <div class="searched_num">
            <h1>
              검색된 판매자
              <span>{{ this.numberFormat(this.searchList.length) }}</span
              >명
            </h1>
          </div>
          <div class="right_con right_con2">
            <div
              class="partner_view"
              v-for="item in this.viewList"
              :key="item.id"
              @click="this.$router.push(`/seller/detail/${item.id}`)"
            >
              <div class="partner_wrap">
                <div class="user">
                  <div class="user_type_wrap">
                    <div class="user_type">
                      <p>{{ item.businessman_division }}</p>
                    </div>
                    <div class="share click_on">
                      <img
                        src="@/assets/images/common/icon_share_border.svg"
                        alt="클립 아이콘"
                      />
                      <img
                        src="@/assets/images/common/icon_share_b.svg"
                        alt="클립 아이콘"
                      />
                    </div>
                  </div>
                  <div class="user_image_wrap loading">
                    <div class="user_image" v-if="item.save_name">
                      <img
                        :src="item.path + item.save_name"
                        :alt="item.original_name"
                        @load="onImgLoad($event)"
                      />
                    </div>
                    <div class="user_image" v-else>
                      <img
                        src="@/assets/images/common/charactor_01.png"
                        alt="프로필사진"
                        @load="onImgLoad($event)"
                      />
                    </div>
                    <div class="user_class" :class="item.level_icon">
                      <p>{{ item.level }}</p>
                    </div>
                  </div>
                </div>
                <div class="text">
                  <h1 class="user_name">{{ item.name }}</h1>
                  <div class="star_wrap">
                    <div class="icon_wrap">
                      <img
                        src="@/assets/images/common/icon_star_seller.svg"
                        alt="별"
                      />
                    </div>
                    <span>4.5</span>
                  </div>
                </div>
              </div>

              <!-- <div class="like_wrap">
                        <p>호감도</p>
                        <div class="star_wrap star_01">
                            <img
                            src="@/assets/images/common/icon_star_border.svg"
                            alt="별 외곽선"
                            />
                            <img src="@/assets/images/common/icon_star.svg" alt="별" />
                        </div>
                        <div class="star_wrap star_02">
                            <img
                            src="@/assets/images/common/icon_star_border.svg"
                            alt="별 외곽선"
                            />
                            <img src="@/assets/images/common/icon_star.svg" alt="별" />
                        </div>
                        <div class="star_wrap star_03">
                            <img
                            src="@/assets/images/common/icon_star_border.svg"
                            alt="별 외곽선"
                            />
                            <img src="@/assets/images/common/icon_star.svg" alt="별" />
                        </div>
                        <div class="star_wrap star_04">
                            <img
                            src="@/assets/images/common/icon_star_border.svg"
                            alt="별 외곽선"
                            />
                            <img src="@/assets/images/common/icon_star.svg" alt="별" />
                        </div>
                        <div class="star_wrap star_05">
                            <img
                            src="@/assets/images/common/icon_star_border.svg"
                            alt="별 외곽선"
                            />
                            <img src="@/assets/images/common/icon_star.svg" alt="별" />
                        </div>
                    </div> -->

              <!--마우스 호버했을 때 btm_section 자리에 info_wrap 대신 hashtag_wrap 보이기-->
              <!--btm section 시작-->
              <div class="btm_section">
                <div class="info_wrap">
                  <div class="info">
                    <p><span></span>카테고리</p>
                    <h1>
                      {{ item.category[0].category
                      }}<span v-if="item.category.length > 1">
                        외 {{ item.category.length - 1 }}개</span
                      >
                    </h1>
                  </div>

                  <div class="info">
                    <p><span></span>판매경력</p>
                    <h1>{{ item.career }}</h1>
                  </div>

                  <div class="info">
                    <p><span></span>판매채널</p>
                    <h1>
                      {{ item.channel[0].channel }}
                      <span v-if="item.channel.length > 1"
                        >외 {{ item.channel.length - 1 }}개</span
                      >
                    </h1>
                  </div>

                  <div class="info">
                    <p><span></span>활동지역</p>
                    <h1>
                      {{ item.area[0].area }}
                      <span v-if="item.area.length > 1"
                        >외 {{ item.area.length - 1 }}곳</span
                      >
                    </h1>
                  </div>
                </div>

                <div class="hashtag_wrap">
                  <span
                    class="hashtag"
                    v-for="(hashtag, i) in item.hashtag"
                    :key="i"
                    >{{ hashtag.hashtag }}</span
                  >
                  <span class="no_hashtag" v-if="item.hashtag.length == 0"
                    >해시태그 없음</span
                  >
                </div>
              </div>
              <!--btm section 끝-->

              <div class="date_wrap">
                <span class="date_info">
                  {{
                    this.dateFormat(item.enrollment_date)
                  }}
                  등록
                </span>
                <span class="active_info">
                  {{ this.activeDateCount(item.login_date) }}
                </span>
              </div>
            </div>
          </div>

          <div class="default_view" v-if="this.searchList.length == 0">
            해당되는 판매자가 없습니다.
          </div>

          <!-- <div class="box_page_number">
                            <a href="#"><span class="gt">&lt;</span></a>
                            <a href="#" class="this_page">1</a>
                            <a href="#">2</a>
                            <a href="#">3</a>
                            <a href="#">4</a>
                            <a href="#">5</a>
                            <a href="#">6</a>
                            <a href="#">7</a>
                            <a href="#">8</a>
                            <a href="#">9</a>
                            <a href="#">10</a>
                            <a href="#"><span class="gt">></span></a>
                        </div> -->
        </div>
      </div>
      <!--상품 끝-->
    </div>
    <ToTop />
  </div>
</template>

<script>
import ToTop from "@/components/ToTop";
import { fetchData } from "@/utils/fetchData";
import { dateFormat } from "@/utils/dateFormat";
import { leftDateCount } from "@/utils/leftDateCount";
import { numberFormat } from "@/utils/numberFormat";
import { sortUp, sortDown } from "@/utils/sortItems.js";

const MAX_SELLER_CARD = 20;

export default {
  components: {
    ToTop,
  },
  mounted() {
    this.fetchBasicData();
    this.loadMoreList();
    window.addEventListener("scroll", this.loadMoreList);
  },
  unmounted() {
    window.removeEventListener("scroll", this.loadMoreList);
  },
  data() {
    return {
      showFilter: false,
      searchInput: "",
      searchList: [],
      viewList: [],
      hashTag: [],

      page: 0, // 무한 스크롤을 위한 page

      sortBy: {
        by: "enrollment_date",
        option: "down",
      },
      // 보낼 데이터
      area: [],
      budget: [],
      businessman_division: [],
      career: [],
      category: [],
      channel: [],

      // 받아올 리스트 데이터
      list: [],

      // 받아올 기본 데이터
      basicData: {
        area: [],
        budget: [],
        businessman_division: [],
        career: [],
        category: [],
        channel: [],
      },
    };
  },
  watch: {
    // 검색 입력값 변경시 동작
    searchInput(search) {
      // searchList 초기화
      if (search == "") {
        this.page = 0;
        this.searchList = this.list;
        // 정렬 순으로
        if (this.sortBy.option === "up") {
          this.searchList = this.sortByUp(this.searchList, this.sortBy.by);
        } else if (this.sortBy.option === "down") {
          this.searchList = this.sortByDown(this.searchList, this.sortBy.by);
        }
      }
    },
    searchList() {
      //this.viewList = this.searchList.slice(this.page*MAX_SELLER_CARD, (this.page+1)*MAX_SELLER_CARD);
      const newViewList = this.searchList.slice(
        this.page * MAX_SELLER_CARD,
        (this.page + 1) * MAX_SELLER_CARD
      );

      this.viewList = newViewList;
      this.page++;
    },
    sortBy() {
      this.page = 0;
      if (this.sortBy.option === "up") {
        this.searchList = this.sortByUp(this.searchList, this.sortBy.by);
      } else if (this.sortBy.option === "down") {
        this.searchList = this.sortByDown(this.searchList, this.sortBy.by);
      } else {
        this.searchList = this.list;
      }
    },
  },
  mixins: [
    fetchData,
    numberFormat,
    sortUp,
    sortDown,
    dateFormat,
    leftDateCount,
  ],
  methods: {
    // 기본 메뉴 데이터 불러오기
    fetchBasicData() {
      this.fetchData("/getBasic?page=seller", (data) => {
        this.basicData.area = data.area;
        this.basicData.budget = data.budget;
        this.basicData.businessman_division = data.businessman_division;
        this.basicData.career = data.career;
        this.basicData.category = data.category;
        this.basicData.channel = data.channel;

        this.list = data.list;

        this.page = 0;
        this.searchList = this.list;
        this.sortBy = {
          by: "enrollment_date",
          option: "down",
        };
      });
    },
    // 서브메뉴 펼치기/접기
    toggleSubmenu(event) {
      event.currentTarget.classList.toggle("on");
    },
    // 서브메뉴 선택
    submenuCheck(event, which, content) {
      // 체크
      if (event.currentTarget.checked) {
        if (which == "category") {
          this.category.push(event.currentTarget.value);
        } else if (which == "area") {
          this.area.push(event.currentTarget.value);
        } else if (which == "channel") {
          this.channel.push(event.currentTarget.value);
        } else if (which == "career") {
          this.career.push(event.currentTarget.value);
        } else if (which == "budget") {
          this.budget.push(event.currentTarget.value);
        } else {
          this.businessman_division.push(event.currentTarget.value);
        }
        this.hashTag.push({
          which: which,
          value: event.currentTarget.value,
          content: content,
        });
      } else {
        // 언체크
        if (which == "category") {
          this.category.splice(
            this.category.indexOf(event.currentTarget.value),
            1
          );
        } else if (which == "area") {
          this.area.splice(this.area.indexOf(event.currentTarget.value), 1);
        } else if (which == "channel") {
          this.channel.splice(
            this.channel.indexOf(event.currentTarget.value),
            1
          );
        } else if (which == "career") {
          this.career.splice(this.career.indexOf(event.currentTarget.value), 1);
        } else if (which == "budget") {
          this.budget.splice(this.budget.indexOf(event.currentTarget.value), 1);
        } else {
          this.businessman_division.splice(
            this.businessman_division.indexOf(event.currentTarget.value),
            1
          );
        }

        let indexHash;
        this.hashTag.forEach((el, i) => {
          if (el.content == content) {
            indexHash = i;
          }
        });
        this.hashTag.splice(indexHash, 1);
      }
    },
    // 검색
    search() {
      this.page = 0;
      if (this.searchInput == "") {
        return;
      } else {
        this.viewList = [];
        this.searchList = this.list;
        const searchWords = this.searchInput.split(" ");

        searchWords.forEach((word) => {
          this.searchList = this.searchList.filter(
            (item) =>
              item.name.toLowerCase().includes(word.toLowerCase()) || // 닉네임 일치여부
              item.hashtag.some((el) =>
                el.hashtag.toLowerCase().includes(word.toLowerCase())
              ) // 해시태그 일치여부
          );
        });
        this.sortBy = {
          by: "enrollment_date",
          option: "down",
        };
      }
      //this.viewList = [...this.viewList, ...this.searchList.slice(this.page*MAX_SELLER_CARD, (this.page+1)*MAX_SELLER_CARD)];
    },
    // 조건 적용
    applyCheck() {
      // 다시 불러왔을 때 같은 자리에 같은 아이템이 있다면 cached데이터를 이용하여 load이벤트를 체크하지 못함.
      // const elements = document.querySelectorAll(".user .user_image_wrap");
      // elements.forEach((el) => el.classList.add("loading"));
      this.fetchData(
        "/getSeller",
        (data) => {
          this.list = [];
          this.searchList = [];
          this.viewList = [];
          this.list = data.list;

          this.page = 0;
          this.searchList = this.list;
          this.sortBy = {
            by: "enrollment_date",
            option: "down",
          };
        },
        {
          category: this.category.toString(),
          area: this.area.toString(),
          channel: this.channel.toString(),
          career: this.career.toString(),
          budget: this.budget.toString(),
          businessman_division: this.businessman_division.toString(),
        }
      );
      this.showFilter = false;
      this.searchInput = "";
    },
    // 해시태그 삭제
    deleteHash(item, i) {
      this.hashTag.splice(i, 1);

      if (item.which == "category") {
        this.category.splice(this.category.indexOf(item.value), 1);
        document.querySelectorAll(".seller_category").forEach((el) => {
          if (el.value == item.value) {
            el.checked = false;
          }
        });
      } else if (item.which == "area") {
        this.area.splice(this.area.indexOf(item.value), 1);
        document.querySelectorAll(".seller_area").forEach((el) => {
          if (el.value == item.value) {
            el.checked = false;
          }
        });
      } else if (item.which == "channel") {
        this.channel.splice(this.channel.indexOf(item.value), 1);
        document.querySelectorAll(".seller_channel").forEach((el) => {
          if (el.value == item.value) {
            el.checked = false;
          }
        });
      } else if (item.which == "career") {
        this.career.splice(this.career.indexOf(item.value), 1);
        document.querySelectorAll(".seller_career").forEach((el) => {
          if (el.value == item.value) {
            el.checked = false;
          }
        });
      } else if (item.which == "budget") {
        this.budget.splice(this.budget.indexOf(item.value), 1);
        document.querySelectorAll(".seller_budget").forEach((el) => {
          if (el.value == item.value) {
            el.checked = false;
          }
        });
      } else {
        this.businessman_division.splice(
          this.businessman_division.indexOf(item.value),
          1
        );
        document
          .querySelectorAll(".seller_businessman_division")
          .forEach((el) => {
            if (el.value == item.value) {
              el.checked = false;
            }
          });
      }
    },
    // 해시태그 리셋
    resetCheck() {
      this.hashTag = [];
      this.area = [];
      this.budget = [];
      this.businessman_division = [];
      this.career = [];
      this.category = [];
      this.channel = [];
      document
        .querySelectorAll(".seller_category")
        .forEach((el) => (el.checked = false));
      document
        .querySelectorAll(".seller_area")
        .forEach((el) => (el.checked = false));
      document
        .querySelectorAll(".seller_channel")
        .forEach((el) => (el.checked = false));
      document
        .querySelectorAll(".seller_career")
        .forEach((el) => (el.checked = false));
      document
        .querySelectorAll(".seller_budget")
        .forEach((el) => (el.checked = false));
      document
        .querySelectorAll(".seller_businessman_division")
        .forEach((el) => (el.checked = false));

      document
        .querySelectorAll(".mainMenu.on")
        .forEach((el) => el.classList.remove("on")); // 메뉴창 닫기
      this.applyCheck(); // 리셋후 다시 데이터 받음(전체 리스트)
      this.showFilter = false;
    },
    // 스크롤시 추가 리스트 로드
    loadMoreList() {
      // 최하단 인식 안되는 문제로 0.5 더하여 ceiling 처리
      if (
        Math.ceil(window.scrollY + window.innerHeight + 0.5) >=
        document.body.clientHeight
      ) {
        const parsed = this.searchList.slice(
          this.page * MAX_SELLER_CARD,
          (this.page + 1) * MAX_SELLER_CARD
        );

        this.viewList = [...this.viewList, ...parsed];
        this.page++;
      }
    },
    onImgLoad(event) {
      event.currentTarget.parentElement.parentElement.classList.remove(
        "loading"
      );
    },
  },
};
</script>

<style scoped src="@/assets/css/layout_provider.css"></style>
